interface NamedPerson {
  lastName?: string | null;
  firstName?: string | null;
  title?: string | null;
}

export function fullName<P extends NamedPerson>(person?: P | null | undefined, withTitle = false): string {
  const { lastName, firstName, title } = person || {};

  const optimalName = lastName && firstName ? `${lastName} ${firstName}` : null;
  const finalName = optimalName || lastName || firstName || "?";

  if (withTitle && title) {
    return `${title} ${finalName}`;
  }

  return finalName;
}
